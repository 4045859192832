import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const SystemPlanningPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reportImg: file(relativePath: { eq: "system-planning.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="System Planning" />
      <Hero
        pageTitle="System Planning"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">
                meet air service forecast needs
              </span>
              <h2>
                System{" "}
                <span className="featured">
                  <span>Planning</span>
                </span>
              </h2>
              <p className="text-max-800">
                Aviation system studies are designed to define the number and
                mix of airport types, their general location and approximate
                time of need, as well as the character of airport facilities
                necessary to meet forecast air service needs of a community,
                region or state. A recommended aviation system emerges only
                after full consideration is given the cost, environmental, and
                aviation implications of a variety of alternative system
                configurations. Other considerations in the planning effort are
                airspace utilization, expansion potential, the ground
                transportation network, suitability of terrain as well as
                existing and planned land use, and development and operating
                costs.
              </p>
              <p>
                With the increased need for economic development and innovative
                financing at airports, aviation system planning has been used
                effectively to undertake non-traditional studies to promote or
                encourage industrial development at airports, track economic
                impacts, or improve air service. In this regard, States have
                broad leeway concerning special studies and are empowered to do
                land use studies, airport noise studies, data collection efforts
                including aircraft operations counts, pavement surveys,
                obstruction identification, access evaluation, and air service
                studies. In addition, system plans can include economic impact
                analyses, special air cargo/mail analyses, AWOS studies, and
                financial analyses and management reviews. Or, if the State
                would desire to assist the individual airports with economic
                development and marketing, airport business planning studies can
                be developed. Again, the possibilities are much more flexible
                than usually thought.
              </p>
              <p>
                R. A. Wiedemann &amp; Associates’ president is one of the most
                experienced aviation system planners in the nation. New and
                innovative approaches to system planning methods have been
                developed by Mr. Wiedemann for use by states, regions, or
                metropolitan areas. Inclusion of multimodal transportation
                interface planning, management and pricing techniques for
                aviation demand dispersion, computer assisted forecasting and
                facilities planning, and indepth contingency planning for
                unexpected occurrences during implementation, have all been
                combined with thorough analysis procedures to enable R. A.
                Wiedemann &amp; Associates to remain at the leading edge of
                aviation system planning.
              </p>
              <Link to="/contact">Contact us about System Planning</Link>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block report-shaddow">
                <Img fluid={data.reportImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SystemPlanningPage
